<template>
  <div v-if="message" class="fixed w-full h-full bg-white/70 pt-44 font-bold text-center px-5 z-10">
    <div v-if="message" :class="messagetype" class="text-center ">
      {{ message }}
    </div>
  </div>
  <div class="w-full text-center fixed h-full bg-gray-500">
    <button class="absolute top-5 right-5" @click="testRelais()">Relais (Test)</button>
    <div class="inline-block">
      <img class="inline-block max-w-full h-32 pt-5" src="@/assets/trit.png">
      <div class="landscape:grid landscape:grid-cols-2">
        <img v-if="!scanMode" @click="scanMode = 'nfc'; window.rescanner.startNFCScan()" src="@/assets/rfid-icon.png" class="p-10 imagebutton m-5 portrait:mt-16">
        <img v-if="!scanMode" @click="scanMode = 'qr'; window.rescanner.startQRScan($refs.video)" src="@/assets/qrscan.png" class="imagebutton m-5">
      </div>
      <video id="video" ref="video" v-show="scanMode == 'qr'" class="inline-block w-96 mt-2 max-h-96" autoplay></video>
      <button v-if="scanMode" class="m-5" @click="stopScan()">Scan abbrechen</button>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      scanMode: false,
      message: "",
      messagetype: "success",
      window: window
    }
  },
  methods: {
    testRelais() {
      window.rescanner.turnRelaisOn()
      setTimeout(() => {
        window.rescanner.turnRelaisOff()
      }, 500)
    },
    stopScan() {
      if(this.scanMode == "qr"){
        window.rescanner.stopQRScan()
      }
      this.scanMode = false
    },
    showMessage(message, type, source) {
      if (source && !["test", this.scanMode].includes(source)) {
        return
      }
      console.log(message)
      this.scanMode = false;
      this.message = message
      this.messagetype = type
      setTimeout(() => {
        this.message = ""
      }, 3000)
    }
  },
  mounted() {
    if (!this.window.rescanner) {
      let vm=this;
      let rescannerLib = document.createElement('script')
      rescannerLib.setAttribute('src', 'https://cdn.rescanner.de/rescanner.js')
      rescannerLib.onload = () => {
        window.rescanner.onNFCSuccess = (message) => {
          vm.showMessage(message, "success", "nfc");
        }
        window.rescanner.onNFCError = (message) => {
          vm.showMessage(message, "error", "nfc");
        }
        window.rescanner.onQRSuccess = (message) => {
          vm.showMessage(message, "success", "qr");
        },
        window.rescanner.onQRError = (message) => {
          vm.showMessage(message, "error", "qr");
        }
      }
      document.head.appendChild(rescannerLib);
    }
  }
}
</script>

<style scoped>
video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.imagebutton {
  @apply inline-block;
  @apply w-48;
  @apply h-48;
  @apply shadow-xl;
  @apply bg-white;
}

.error {
  @apply bg-red-500;
  @apply text-white;
  @apply px-4;
  @apply py-2;
  @apply rounded-lg;
}

.success {
  @apply bg-green-500;
  @apply text-white;
  @apply px-4;
  @apply py-2;
  @apply rounded-lg;
}

button {
  @apply px-4;
  @apply py-2;
  @apply shadow-md;
  @apply font-bold;
  @apply rounded-lg;
  @apply bg-white;
  @apply border-4;
  @apply border-gray-400;
}
</style>